<template>
  <layout-auth>
    <auth-form-base>
      <h4>Recover password</h4>
      <div class="my-1">
        Please, enter your e-mail address and we'll send you an e-mail with a
        link to reset your password.
      </div>
      <v-form>
        <v-text-field
          v-model="user"
          prepend-icon="mdi-email"
          label="E-mail"
          type="text"
        />
      </v-form>
      <v-container class="pa-0">
        <v-row justify="end" dense>
          <v-col cols="auto">
            <v-btn text color="primary" :to="{ name: 'login' }">cancel</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" depressed @click="submit">send</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </auth-form-base>
  </layout-auth>
</template>

<script>
import LayoutAuth from './AuthLayout';
import AuthFormBase from './AuthFormBase';
export default {
  name: 'AuthFormForgot',
  components: {
    LayoutAuth,
    AuthFormBase
  },
  data() {
    return {
      errorReason: '',
      user: '',
      password: '',
      error: ''
    };
  },
  methods: {
    submit() {}
  }
};
</script>
