<template>
  <layout-base class="login-layout">
    <v-main>
      <v-container
        fluid
        fill-height
        class="wrapper d-flex align-center justify-center"
      >
        <slot />
      </v-container>
    </v-main>
  </layout-base>
</template>

<script>
import LayoutBase from '@/layouts/LayoutBase';
export default {
  name: 'AuthLayout',
  components: {
    LayoutBase
  }
};
</script>

<style lang="sass" scoped>
.login-layout
  background: #333333 url("/background-login.jpg") no-repeat center / cover
  .wrapper
    width: 100%
    max-width: 380px
</style>
