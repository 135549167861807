export function extractServerErrors(error, fields = []) {
  const { message } = error;
  let result = {};
  if (Array.isArray(message)) {
    fields.forEach(field => {
      let fieldObject = message.find(m => m.property === field);
      if (fieldObject && fieldObject.constraints) {
        result[field] = Object.values(fieldObject.constraints);
      }
    });
  }
  if (typeof message === 'string') {
    result['__error_message__'] = message;
  }
  return result;
}
