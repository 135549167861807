<template>
  <v-card class="auth-base elevation-12" min-width="288">
    <div v-if="isLoading" class="overlay">
      <v-progress-circular
        class="mt-2"
        size="25"
        width="2"
        indeterminate
        color="primary"
        :distance="10"
      />
    </div>
    <v-card-title class="mt-3">
      <img class="mx-auto" :src="logoUrl" height="80px" alt />
    </v-card-title>
    <v-card-text>
      <v-alert
        transition="scale-transition"
        :value="!!message"
        :type="messageType"
        color="error"
        outlined
        class="mb-4"
        >{{ message }}</v-alert
      >
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AuthFormBase',
  props: {
    message: {
      type: String,
      default: ''
    },
    isLoadingFromApp: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: 'error'
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    logoUrl() {
      return this.$vuetify.theme.dark
        ? require('@/assets/monitor_logo_large_dark.svg')
        : require('@/assets/monitor_logo_large_light.svg');
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search.substring(1));

    if (params.get('token')) {
      this.isLoading = true;
    }
  }
};
</script>

<style lang="sass" scoped>
.auth-base
  width: 100%

.overlay
  z-index: 1
  width: 100%
  height: 100%
  background-color: rgba(255, 255, 255, 0.9)
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
</style>
