<template>
  <layout-auth>
    <auth-form-base>
      <h4>New password</h4>
      <div class="my-1">
        Please, enter new password.
      </div>
      <v-form>
        <v-text-field
          v-model="password"
          prepend-icon="mdi-lock"
          label="Password"
          type="password"
        />
      </v-form>
      <v-container class="pa-0">
        <v-row justify="end" dense>
          <v-col cols="auto">
            <v-btn color="primary" depressed @click="submit">send</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </auth-form-base>
  </layout-auth>
</template>

<script>
import LayoutAuth from './AuthLayout';
import AuthFormBase from './AuthFormBase';
export default {
  name: 'AuthFormNewPassword',
  components: {
    LayoutAuth,
    AuthFormBase
  },
  data() {
    return {
      errorReason: '',
      user: '',
      password: '',
      error: ''
    };
  },
  methods: {
    submit() {}
  }
};
</script>
