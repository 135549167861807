<template>
  <auth-layout>
    <v-card class="auth-base elevation-12" width="300px">
      <v-card-title class="mt-3">
        <img class="mx-auto" :src="logoUrl" alt />
      </v-card-title>
      <v-card-text class="text-center">
        Entering to app...
      </v-card-text>
      <v-card-text class="text-center pt-0 pb-6">
        <v-progress-circular
          class="mt-2"
          size="30"
          width="2"
          indeterminate
          color="primary"
          :distance="10"
        />
      </v-card-text>
    </v-card>
  </auth-layout>
</template>
<script>
import AuthLayout from '@/modules/auth/ui/AuthLayout';
import { computed, onMounted } from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
import router from '@/router';

export default {
  name: 'LoginFromApp',
  components: { AuthLayout },
  setup(props, { root }) {
    const { loginFromApp } = useAuth();
    const logoUrl = computed(() => {
      return root.$vuetify.theme.dark
        ? require('@/assets/monitor_logo_dark.svg')
        : require('@/assets/monitor_logo_light.svg');
    });

    onMounted(async () => {
      try {
        const params = new URLSearchParams(window.location.search.substring(1));

        if (params.get('token')) {
          localStorage.setItem('refreshToken', params.get('token'));
          localStorage.setItem('tokenId', params.get('tokenId'));
          await loginFromApp();
        }
        router.push('/');
      } catch (e) {
        router.push('/login');
        throw new Error(e);
      }
    });

    return {
      logoUrl
    };
  }
};
</script>
