<template>
  <layout-auth>
    <validation-observer ref="vobserver">
      <validation-provider v-slot="props" name="__error_message__">
        <auth-form-base :message="props.errors.join()">
          <v-form ref="form">
            <ValidationProvider v-slot="{ errors }" name="email">
              <v-text-field
                id="login-inp"
                v-model.trim="email"
                prepend-icon="mdi-account"
                label="Login"
                :error-messages="errors"
                type="text"
              />
            </ValidationProvider>
            <v-text-field
              id="password-inp"
              v-model.trim="password"
              prepend-icon="mdi-lock"
              label="Password"
              type="password"
            />
          </v-form>
          <v-btn
            id="login-btn"
            color="primary"
            class="mt-4 mb-3"
            :loading="isLoading"
            :disabled="isLoading"
            block
            depressed
            @click="submit"
          >
            Login
          </v-btn>
        </auth-form-base>
      </validation-provider>
    </validation-observer>
  </layout-auth>
</template>

<script>
import LayoutAuth from './AuthLayout';
import AuthFormBase from './AuthFormBase';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { extractServerErrors } from '@/utils/validation';

import { useAuth } from '@/modules/auth/api';
import { ref } from '@vue/composition-api';
export default {
  name: 'AuthFormLogin',
  components: {
    LayoutAuth,
    AuthFormBase,
    ValidationObserver,
    ValidationProvider
  },
  setup(props, context) {
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const isLoading = ref(false);
    const { login } = new useAuth(context);

    const submit = async () => {
      try {
        isLoading.value = true;
        await login({
          userLogin: email.value,
          userPassword: password.value
        });
        const last = localStorage.getItem('lastUrlPathname');
        localStorage.removeItem('lastUrlPathname');
        if (last && !last.includes('login')) {
          context.root.$router.push(last);
        } else {
          context.root.$router.push({ name: 'objects' });
        }
      } catch (e) {
        context.refs.vobserver.setErrors(
          extractServerErrors(e, ['email', 'password'])
        );
      } finally {
        isLoading.value = false;
      }
    };

    return {
      email,
      password,
      errorMessage,
      submit,
      isLoading
    };
  }
};
</script>
